<script setup>
</script>

<template>
  <Navbar></Navbar>
  <slot />
</template>

<style lang="scss" scoped>
</style>
